import { Routes, Route } from "react-router-dom";
import { lazyImport } from "src/utils";
const { TemplateOne } = lazyImport(
  () => import("src/cvTemplates/T1"),
  "TemplateOne"
);
const { TemplateTwo } = lazyImport(
  () => import("src/cvTemplates/T2"),
  "TemplateTwo"
);
const { TemplateThree } = lazyImport(
  () => import("src/cvTemplates/T3"),
  "TemplateThree"
);
const { Forbidden } = lazyImport(
  () => import("src/components/Forbidden"),
  "Forbidden"
);
const { NotFound } = lazyImport(
  () => import("src/components/NotFound"),
  "NotFound"
);

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/cv/template/1" element={<TemplateOne />} />
      <Route path="/cv/template/2" element={<TemplateTwo />} />
      <Route path="/cv/template/3" element={<TemplateThree />} />
      <Route path="/forbbiden" element={<Forbidden />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
